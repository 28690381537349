import { ReactNode } from 'react'
import classNames from 'classnames'
import { isIOS } from 'react-device-detect'
import AndroidPullToRefreshSpinner from '@/components/common/header/AndroidPullToRefreshSpinner'
import IosPullToRefreshSpinner from '@/components/common/header/IosPullToRefreshSpinner'
import SkeletonBase from '@/components/common/skeleton/SkeletonBase'
import { PullToRefreshStatusEnum } from '@/hooks/common/usePullToRefresh'

type HeaderProps = {
  leftSide?: ReactNode
  center?: ReactNode
  rightSide?: ReactNode
  pullToRefreshStatus?: PullToRefreshStatusEnum
}

/**
 * 디자인 시스템을 따르는 헤더 입니다.
 * 3가지 props를 받으며 모두 reactNode로 줍니다.
 *
 * @link: https://www.figma.com/file/bnWIiHfgHgV5QyiVP5ek8t/Design-System?node-id=86-612&t=6kw3ARvFp25quxV3-0
 * @param leftSide: left 사이드에 들어갈 children 입니다.
 * @param center: 타이틀 입니다. 여기에 쓰일 tag는 seo 에서는 h1이여야 합니다.
 * @param rightSide: right 사이드에 들어갈 children 입니다.
 * @param pullToRefreshStatus: pullToRefresh의 상태값 입니다.
 */
const Header = ({ leftSide, center, rightSide, pullToRefreshStatus }: HeaderProps) => {
  return (
    <>
      <header className="fixed top-0 z-header flex h-[calc(var(--safe-area-inset-top)+5.7rem)] w-full max-w-body place-content-between items-end gap-16 bg-white p-16 text-gray">
        <div className={classNames('flex h-24 items-center justify-start')}>{leftSide}</div>
        <div className="body1 flex items-center justify-center truncate text-center font-bold text-gray">{center}</div>
        <div className="flex h-24 items-center justify-end">{rightSide}</div>
      </header>
      <div className="flex min-h-[calc(var(--safe-area-inset-top)+5.7rem)] w-full max-w-body items-end justify-center bg-white">
        {isIOS ? (
          <IosPullToRefreshSpinner pullToRefreshStatus={pullToRefreshStatus} />
        ) : (
          <AndroidPullToRefreshSpinner pullToRefreshStatus={pullToRefreshStatus} />
        )}
      </div>
    </>
  )
}

export default Header

export const SkeletonHeaderCenter = () => {
  return <SkeletonBase className="h-25 w-200 rounded-xss" />
}
