import { useEffect, useMemo, useRef, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useNodesList } from '@/api/generated/hooks'
import { NodeRes, SiteRes } from '@/api/generated/types'
import { SiteMapBottomSheetDataType } from '@/components/home/SiteMapBottomSheet'
import GoogleMapContainer, { CoordinateType, MarkerEnum, MarkerStyle } from '@/containers/GoogleMapContainer'
import useBottomSheetLegacy from '@/hooks/common/useBottomSheetLegacy'
import useDropDown from '@/hooks/common/useDropDown'
import { isInApp } from '@/pages/_app'
import { LocalStorage, LocalStorageKeyEnum } from '@/utils/localStorage'
import NativeBridgeAction from '@/utils/native-bridge/NativeBridgeAction'

const usePromiseNode = ({ site }: { site?: SiteRes }) => {
  const siteMapBottomSheetControls = useBottomSheetLegacy<SiteMapBottomSheetDataType>()
  const siteNodeDropDownControls = useDropDown()
  const { googleMap, isGoogleMapLoaded, drawMarker, removeMarker, moveTo, initGoogleMap } =
    GoogleMapContainer.useContainer()
  const prevSelectedDestinationMarkerRef = useRef<NodeRes>()
  const { data: nodeListOri, isFetching: isNodeListFetching } = useNodesList({
    query: {
      cacheTime: 60 * 60 * 1000,
      staleTime: 60 * 60 * 1000
    }
  })
  const nodeList = useMemo(() => {
    if (!nodeListOri) {
      return undefined
    }
    const toStringForCompare = (name: string): string => {
      const indexOfComma = name.indexOf(',')
      const indexOfRange = name.indexOf('~')

      let result = name
      if (indexOfComma === -1 && indexOfRange === -1) {
        result = name
      } else if (indexOfComma !== -1 && indexOfRange !== -1) {
        const index = indexOfComma < indexOfRange ? indexOfComma : indexOfRange
        result = name.slice(0, index)
      } else if (indexOfComma !== -1) {
        result = name.slice(0, indexOfComma)
      } else {
        result = name.slice(0, indexOfRange)
      }

      return result.padStart(4, '0')
    }
    return nodeListOri?.sort((a, b) => {
      const resultA = toStringForCompare(a.name)
      const resultB = toStringForCompare(b.name)
      if (resultA === resultB) {
        return 0
      }
      return resultA > resultB ? 1 : -1
    })
  }, [nodeListOri])

  const handleSiteMapOpen = () => {
    siteMapBottomSheetControls.setMountAfterCallbackRef(() => {
      if (!site?.centerLatitude || !site?.centerLongitude || !nodeList) {
        return
      }
      initGoogleMap(site.centerLatitude, site.centerLongitude)
      siteMapBottomSheetControls.setBottomSheetData((prev) => ({ ...prev, site, nodeList }))
      const optionList = nodeList.map((node) => ({ id: node.nodeNumber, content: node.name }))
      siteNodeDropDownControls.setOptionList(optionList)
      const promiseNodeNumber = LocalStorage.getItem<NodeRes['nodeNumber']>(LocalStorageKeyEnum.PromiseNodeNumber)
      if (promiseNodeNumber) {
        const targetOption = optionList.find((option) => option.id === promiseNodeNumber)
        targetOption && siteNodeDropDownControls.setSelectedItem(targetOption)
      }
    })
    siteMapBottomSheetControls.handleOpen()
  }

  /**
   * 1. 선택되지 않은 노드들만 일반 목적지 마커로 그립니다.
   * 2. 선택된 노드만 활성화 목적지 마커로 그립니다.
   */
  const drawDestinationMarker = (node: NodeRes, isActive: boolean) => {
    if (!googleMap || !isGoogleMapLoaded || !nodeList) return
    drawMarker({
      longitude: node.longitude,
      latitude: node.latitude,
      markerType: isActive ? MarkerEnum.ActiveDestinationNode : MarkerEnum.DestinationNode,
      markerMarkup: isActive
        ? MarkerStyle[MarkerEnum.ActiveDestinationNode](node.name)
        : MarkerStyle[MarkerEnum.DestinationNode](node.name),
      onClickListener: () => {
        moveTo(node.latitude, node.longitude)
        const optionList = nodeList.map((node) => ({ id: node.nodeNumber, content: node.name }))
        const targetOption = optionList.find((option) => option.id === node.nodeNumber)
        siteNodeDropDownControls.setSelectedItem(targetOption)
      }
    })
  }

  const drawCurrentLocation = (coordinate: CoordinateType) => {
    if (!googleMap || !isGoogleMapLoaded || !nodeList) return
    drawMarker({
      longitude: coordinate.longitude,
      latitude: coordinate.latitude,
      markerType: MarkerEnum.CurrentNode,
      markerMarkup: MarkerStyle[MarkerEnum.CurrentNode]()
    })
  }

  useEffect(() => {
    if (!googleMap || !isGoogleMapLoaded || !nodeList) return
    nodeList.forEach((node) => drawDestinationMarker(node, false))

    return () => {
      removeMarker([MarkerEnum.DestinationNode])
    }
  }, [googleMap, isGoogleMapLoaded])

  useEffect(() => {
    if (!googleMap || !isGoogleMapLoaded || !nodeList) return
    // 이전 선택된 활성화 마커를 지우고 일반 마커로 그립니다.
    if (prevSelectedDestinationMarkerRef.current) {
      const prevSelectedNode = prevSelectedDestinationMarkerRef.current
      removeMarker([MarkerEnum.ActiveDestinationNode])
      drawDestinationMarker(prevSelectedNode, false)
    }

    if (isEmpty(siteNodeDropDownControls.selectedItem)) return
    const selectedNode = nodeList.find((node) => node.nodeNumber === siteNodeDropDownControls.selectedItem?.id)
    if (isEmpty(selectedNode)) return
    // 선택된 노드는 Destination 마커로 그려졌었기 때문에 기존마커를 지우고 ActiveDestination 마커로 다시 그립니다.
    removeMarker([MarkerEnum.DestinationNode], { longitude: selectedNode.longitude, latitude: selectedNode.latitude })
    drawDestinationMarker(selectedNode, true)
    moveTo(selectedNode.latitude, selectedNode.longitude)
    return () => {
      prevSelectedDestinationMarkerRef.current = selectedNode
    }
  }, [googleMap, isGoogleMapLoaded, siteNodeDropDownControls.selectedItem])

  // 내 위치 클릭후 마킹
  const handleRequestCurrentLocationClick = () => {
    if (!isInApp()) return
    NativeBridgeAction.location().then((value) => {
      if (!value || !value?.isSuccess || !value?.data?.latitude || !value?.data?.longitude) {
        return
      }
      moveTo(value.data.latitude, value.data.longitude)
      const currentCoordinate: CoordinateType = {
        latitude: value.data.latitude,
        longitude: value.data.longitude
      }
      removeMarker([MarkerEnum.CurrentNode])
      drawCurrentLocation(currentCoordinate)
    })
  }

  // 기본적으로 현재 위치 마킹
  useEffect(() => {
    if (!googleMap) return
    if (!isInApp()) return
    NativeBridgeAction.location().then((value) => {
      if (!value || !value?.isSuccess || !value?.data?.latitude || !value?.data?.longitude) {
        return
      }
      const currentCoordinate: CoordinateType = {
        latitude: value.data.latitude,
        longitude: value.data.longitude
      }
      removeMarker([MarkerEnum.CurrentNode])
      drawCurrentLocation(currentCoordinate)
    })
  }, [googleMap])

  // 약속 장소 설정 (promise node)
  const [promiseNodeName, setPromiseNodeName] = useState<string>()
  const setPromiseNode = ({ nodeId, onClose }: { nodeId?: string; onClose?: () => void } = {}) => {
    if (nodeId) {
      LocalStorage.setItem(LocalStorageKeyEnum.PromiseNodeNumber, nodeId)
      const nodeName = nodeList?.find((node) => node.nodeNumber === nodeId)?.name
      nodeName && setPromiseNodeName(nodeName)
    } else {
      const storageNodeNumber = LocalStorage.getItem<NodeRes['nodeNumber']>(LocalStorageKeyEnum.PromiseNodeNumber)
      const existingNodeName = nodeList?.find((node) => storageNodeNumber === node.nodeNumber)?.name
      existingNodeName && setPromiseNodeName(existingNodeName)
    }
    onClose?.()
  }
  useEffect(() => {
    if (!nodeList) return
    setPromiseNode()
  }, [nodeList])

  return {
    siteMapBottomSheetControls,
    siteNodeDropDownControls,
    handleSiteMapOpen,
    nodeList,
    handleRequestCurrentLocationClick,
    promiseNodeName,
    setPromiseNode,
    isNodeListFetching
  }
}

export default usePromiseNode
