import { useEffect, useRef, useState } from 'react'
import { FeatureCollection } from 'geojson'
import isEmpty from 'lodash/isEmpty'
import { createContainer } from 'unstated-next'

/**
 * 구글 맵 api
 * @link https://developers.google.com/maps/documentation/javascript/reference/map?hl=ko#MapOptions.gestureHandling
 */
export type CoordinateType = {
  /** 위도 */
  latitude: number
  /** 경도 */
  longitude: number
}

export enum GeometryTypeEnum {
  Point = 'Point',
  MultiPoint = 'MultiPoint',
  LineString = 'LineString',
  MultiLineString = 'MultiLineString',
  Polygon = 'Polygon',
  MultiPolygon = 'MultiPolygon',
  GeometryCollection = 'GeometryCollection'
}

export type MarkerType = {
  mapMarker: google.maps.marker.AdvancedMarkerElement
  markerType: MarkerEnum
}

export enum MarkerEnum {
  DestinationNode = 'DestinationNode',
  ActiveDestinationNode = 'ActiveDestinationNode',
  CurrentNode = 'CurrentNode'
}
export enum PolylineEnum {
  CurrentPath = 'CurrentPath',
  GlobalPath = 'GlobalPath',
  CustomPath = 'CustomPath'
}

export type PolylineType = {
  polyline: google.maps.Polyline
  polylineType: PolylineEnum
}

export enum GeoJsonEnum {
  CurrentPath = 'CurrentPath',
  GlobalPath = 'GlobalPath'
}

export type GeoJsonType = {
  geoJson: FeatureCollection
  geoJsonType: GeoJsonEnum
}

export enum MapEventEnum {
  DestinationClick = 'DestinationClick'
}

export type MapEventListenerType = {
  mapEventListener: google.maps.MapsEventListener
  mapEventType: MapEventEnum
}

const googleMapScriptId = 'googleMap'

const correctionPositionStyle = 'position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);'
export const MarkerStyle = {
  [MarkerEnum.ActiveDestinationNode]: (nodeName: string) =>
    `<div style="display: flex; flex-direction: column; justify-content: center; align-items: center; z-index: 10; ${correctionPositionStyle}" ><svg width="4rem" height="4rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 10.2C4 5.69801 7.55535 2 12 2C16.4446 2 20 5.69801 20 10.2C20 12.4965 18.9859 14.5068 17.613 16.3405C16.4671 17.871 15.0003 19.3666 13.5411 20.8543C13.2654 21.1355 12.99 21.4163 12.717 21.6971C12.5287 21.8907 12.2701 22 12 22C11.7299 22 11.4713 21.8907 11.283 21.6971C11.01 21.4163 10.7346 21.1355 10.4589 20.8543C8.99974 19.3666 7.53292 17.871 6.38702 16.3405C5.01406 14.5068 4 12.4965 4 10.2ZM9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10Z" fill="#00A5E0"/></svg><span class="caption" style="font-weight: 700; white-space: nowrap;">${nodeName}</span></div>`,
  [MarkerEnum.DestinationNode]: (nodeName: string) =>
    `<div style="display: flex; flex-direction: column; justify-content: center; align-items: center; z-index: 100; ${correctionPositionStyle}" ><svg width="2.8rem" height="2.8rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 10.2C4 5.69801 7.55535 2 12 2C16.4446 2 20 5.69801 20 10.2C20 12.4965 18.9859 14.5068 17.613 16.3405C16.4671 17.871 15.0003 19.3666 13.5411 20.8543C13.2654 21.1355 12.99 21.4163 12.717 21.6971C12.5287 21.8907 12.2701 22 12 22C11.7299 22 11.4713 21.8907 11.283 21.6971C11.01 21.4163 10.7346 21.1355 10.4589 20.8543C8.99974 19.3666 7.53292 17.871 6.38702 16.3405C5.01406 14.5068 4 12.4965 4 10.2ZM9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10Z" fill="#7B7B7B"/></svg><span class="caption" style="font-weight: 700; white-space: nowrap;">${nodeName}</span></div>`,
  [MarkerEnum.CurrentNode]: () =>
    `<div style="display: flex; height: 24px; width: 24px; align-items: center; justify-content: center; border-radius: 50%; background-color: rgba(0, 180, 90, 0.3); ${correctionPositionStyle}"><div style="height: 14px; width: 14px; border-spacing: 1px; border-radius: 50%; border: 1px solid white; background-color: #00B45A;"></div></div>`
}

const GoogleMapContainer = createContainer(() => {
  const [isGoogleMapLoaded, setIsGoogleMapLoaded] = useState(false)
  const [googleMapCenter, setGoogleMapCenter] = useState({ latitude: 0, longitude: 0 })
  const [googleMap, setGoogleMap] = useState<google.maps.Map>()
  const markers = useRef<MarkerType[]>([])
  const polylines = useRef<PolylineType[]>([])
  const geoJsons = useRef<GeoJsonType[]>([])
  const mapEventListeners = useRef<MapEventListenerType[]>([])

  const setGoogleMapLoadFlag = () => {
    setIsGoogleMapLoaded(true)
  }

  useEffect(() => {
    if (!process.env.GOOGLEMAP_KEY) {
      alert('googlemap api not defined')
      return
    }
    const googleMapScript = document.getElementById(googleMapScriptId)
    if (googleMapScript) {
      setGoogleMapLoadFlag()
      return
    }

    window.onLoadGoogleMap = () => setGoogleMapLoadFlag()
    const $script = document.createElement('script')
    $script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLEMAP_KEY}&libraries=drawing,marker&v=weekly&callback=onLoadGoogleMap`
    $script.async = true
    $script.id = googleMapScriptId
    document.head.appendChild($script)
  }, [])

  const initGoogleMap = (latitude?: number, longitude?: number) => {
    const mapElement = document.getElementById('map')
    if (!isGoogleMapLoaded || !latitude || !longitude || !mapElement) return
    if (googleMap) {
      googleMap.unbindAll()
    }
    const options: google.maps.MapOptions = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: 16,
      mapTypeControl: false,
      keyboardShortcuts: false,
      fullscreenControl: false,
      rotateControl: false,
      scaleControl: false,
      zoomControl: false,
      streetViewControl: false,
      gestureHandling: 'greedy',
      mapId: process.env.GOOGLEMAP_ID
    }

    setGoogleMapCenter({ latitude, longitude })
    setGoogleMap(new google.maps.Map(mapElement, options))
  }

  const fetchGeoJson = async (jsonUrl: string, geoJsonType: GeoJsonEnum, filterType: GeometryTypeEnum) => {
    const geoJson = await fetch(jsonUrl).then((res) => res.json())
    const filteredGeoJson = filterType ? extractGeoJson(geoJson, filterType) : geoJson
    geoJsons.current.push({ geoJson: filteredGeoJson, geoJsonType })
    return
  }

  const extractGeoJson = (geoJson: FeatureCollection, type: GeometryTypeEnum): FeatureCollection => {
    return {
      ...geoJson,
      features: geoJson.features.filter((feature) => feature.geometry.type === type)
    }
  }

  /**
   * Todo: GeoJson을 googleMap에 추가하는 과정을 정의 해야합니다.
   */

  const removeGeoJson = (feature: google.maps.Data.Feature) => {
    if (!googleMap) return
    googleMap.data.remove(feature)
  }

  const drawPolyLineByCoordinateList = (
    coordinateList: CoordinateType[],
    polylineType: PolylineEnum,
    color: string,
    zIndex: number,
    mapEventType?: MapEventEnum,
    onClickListener?: (event: any) => void
  ) => {
    const naverPath = coordinateList.map((coordinate) => ({ lat: coordinate.latitude, lng: coordinate.longitude }))
    const polyline = new google.maps.Polyline({
      map: googleMap,
      path: naverPath,
      strokeColor: color,
      strokeWeight: 5,
      clickable: true,
      zIndex
    })
    polylines.current.push({ polylineType, polyline })
    onClickListener && mapEventType && addMapEventListener(polyline, 'click', mapEventType, onClickListener)
    return
  }

  const removePolyline = (removePolylineTypes: PolylineEnum[]) => {
    const removePolylines = polylines.current.filter((polyline) => removePolylineTypes.includes(polyline.polylineType))
    polylines.current = polylines.current.filter((polyline) => !removePolylineTypes.includes(polyline.polylineType))
    removePolylines.forEach((polyline) => polyline.polyline.setMap(null))
  }

  const drawMarker = async ({
    longitude,
    latitude,
    markerType,
    markerMarkup,
    onClickListener
  }: {
    longitude: number
    latitude: number
    markerType: MarkerEnum
    markerMarkup: string
    onClickListener?: (event: any) => void
  }) => {
    if (!googleMap) return

    const getNodeMarker = (markerMarkup: string) => {
      const content = document.createElement('div')
      content.innerHTML = markerMarkup
      return content
    }

    const marker = new google.maps.marker.AdvancedMarkerElement({
      position: new google.maps.LatLng(latitude, longitude),
      map: googleMap,
      content: getNodeMarker(markerMarkup)
    })
    markers.current.push({ markerType, mapMarker: marker })
    onClickListener && addMapEventListener(marker, 'click', MapEventEnum.DestinationClick, onClickListener)
    return marker
  }

  const moveMap = (x: number, y: number) => {
    googleMap?.panBy(x, y)
  }

  const moveTo = (latitude: number, longitude: number, zoom?: number) => {
    googleMap?.panTo(new google.maps.LatLng(latitude, longitude))
    zoom && googleMap?.setZoom(zoom)
  }

  const addMapEventListener = (
    target: google.maps.marker.AdvancedMarkerElement | google.maps.Map | google.maps.Polyline,
    eventName: string,
    mapEventType: MapEventEnum,
    listener: (event: any) => void
  ) => {
    if (!target) return
    try {
      const mapEventListener = google.maps.event.addListener(target, eventName, (event: any) => listener(event))
      mapEventListeners.current.push({ mapEventType, mapEventListener })
      return mapEventListener
    } catch (e) {
      console.log(e, 'ERR:: Add Listener')
    }
  }

  const removeMapEventListener = (removeMapEventType: MapEventEnum[]) => {
    if (isEmpty(mapEventListeners.current)) return
    const removeMapEventListener = mapEventListeners.current.filter((mapEventListener) =>
      removeMapEventType.includes(mapEventListener.mapEventType)
    )
    mapEventListeners.current = mapEventListeners.current.filter(
      (mapEventListener) => !removeMapEventType.includes(mapEventListener.mapEventType)
    )
    removeMapEventListener.forEach((mapEventListener) =>
      google.maps.event.removeListener(mapEventListener.mapEventListener)
    )
  }

  const removeMarker = (removeMarkerTypes: MarkerEnum[], removeMarkerCoordinate?: CoordinateType) => {
    if (!(markers.current && markers.current.length > 0)) return

    const removeMarkers = markers.current.filter((marker) => {
      if (removeMarkerCoordinate) {
        return (
          removeMarkerTypes.includes(marker.markerType) &&
          removeMarkerCoordinate.latitude === marker.mapMarker.position?.lat &&
          removeMarkerCoordinate.longitude === marker.mapMarker.position?.lng
        )
      }
      return removeMarkerTypes.includes(marker.markerType)
    })

    markers.current = markers.current.filter((marker) => {
      if (removeMarkerCoordinate) {
        return !(
          removeMarkerTypes.includes(marker.markerType) &&
          removeMarkerCoordinate.latitude === marker.mapMarker.position?.lat &&
          removeMarkerCoordinate.longitude === marker.mapMarker.position?.lng
        )
      }
      return !removeMarkerTypes.includes(marker.markerType)
    })
    removeMarkers.forEach((marker) => (marker.mapMarker.map = null))
  }

  const getMapCenter = () => {
    return { latitude: googleMap?.getCenter()?.lat() ?? 0, longitude: googleMap?.getCenter()?.lng() ?? 0 }
  }

  return {
    isGoogleMapLoaded,
    initGoogleMap,
    googleMap,
    setGoogleMap,
    markers,
    polylines,
    geoJsons,
    mapEventListeners,
    addMapEventListener,
    drawMarker,
    removeMarker,
    drawPolyLineByCoordinateList,
    fetchGeoJson,
    extractGeoJson,
    removePolyline,
    removeMapEventListener,
    removeGeoJson,
    getMapCenter,
    moveMap,
    moveTo
  }
})

export default GoogleMapContainer
