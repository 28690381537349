import { QueryClient } from '@tanstack/query-core'

const refreshByQueryKey = ({
  queryClient,
  refetchQueryKeys
}: {
  queryClient: QueryClient
  refetchQueryKeys: unknown[][]
}) => {
  return Promise.all(
    refetchQueryKeys.map((queryKey) => {
      return queryClient.invalidateQueries(queryKey)
    })
  )
}

export const FetchUtils = {
  refreshByQueryKey
}
