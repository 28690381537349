import { PropsWithChildren, ReactNode, useEffect } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import Icon from '@/components/common/Icon'
import Portal from '@/components/common/Portal'
import { IconNamesEnum } from '@/constants/iconNames.enum'
import { BottomSheetControlsLegacyType } from '@/hooks/common/useBottomSheetLegacy'
import { StyleUtils } from '@/utils/style'

export type BottomSheetProps = {
  bottomSheetControls: BottomSheetControlsLegacyType
  className?: string
  background?: string
  height?: string
  topSide?: ReactNode
  isClosable?: boolean
}

/**
 * 디자인 시스템을 따르는 바텀 시트 레이아웃 입니다.
 */
const BottomSheetLegacy = ({
  bottomSheetControls,
  className,
  children,
  background = 'bg-white',
  height = 'h-fit',
  topSide,
  isClosable = true
}: PropsWithChildren<BottomSheetProps>) => {
  const { mount, handleClose, controls } = bottomSheetControls

  useEffect(() => {
    if (!mount) {
      StyleUtils.ableMainScroll()
    } else {
      StyleUtils.disabledMainScroll()
    }
    return () => {
      StyleUtils.ableMainScroll()
    }
  }, [mount])

  if (!mount) {
    return null
  }

  return (
    <Portal id="bottom-sheet">
      <motion.div
        animate={controls}
        initial="hidden"
        transition={{ duration: 0.3 }}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 }
        }}
        onClick={(e) => {
          e.preventDefault()
          if (isClosable) {
            handleClose()
          }
        }}
        className="fixed bottom-0 h-screen w-screen max-w-body cursor-pointer bg-gray-900/50"
      />
      <motion.div
        animate={controls}
        initial="hidden"
        role="dialog"
        transition={{
          damping: 40,
          stiffness: 400,
          type: 'spring',
          duration: 0.3
        }}
        variants={{
          hidden: { y: 2000 },
          visible: { y: 0 }
        }}
        className={classNames(`fixed bottom-0 flex w-full max-w-body flex-col justify-end`)}>
        {topSide}
        <div className={classNames('flex w-full flex-col rounded-t-md', background, height, className)}>{children}</div>
      </motion.div>
    </Portal>
  )
}

interface BottomSheetHeaderProps {
  bottomSheetControls: BottomSheetControlsLegacyType
  hasCloseButton?: boolean
}
const BottomSheetHeader = ({
  children,
  bottomSheetControls,
  hasCloseButton = true
}: PropsWithChildren<BottomSheetHeaderProps>) => {
  return (
    <header className="relative mt-32 flex h-fit min-h-40 w-full items-start justify-center gap-16 bg-white px-24 pb-16">
      <div className="body1 items-center justify-center whitespace-pre-wrap px-40 text-center font-bold">
        {children}
      </div>
      {hasCloseButton && (
        <Icon
          name={IconNamesEnum.Close}
          className="absolute right-20 h-24 w-24 cursor-pointer"
          onClick={() => bottomSheetControls.handleClose()}
        />
      )}
    </header>
  )
}

export default BottomSheetLegacy

BottomSheetLegacy.Header = BottomSheetHeader
