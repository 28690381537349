import { PropsWithChildren, ReactNode } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

/**
 * 디자인 시스템을 따르는 CTA 버튼입니다.
 *
 * @link: https://www.figma.com/file/bnWIiHfgHgV5QyiVP5ek8t/Design-System?node-id=125-1075&t=6kw3ARvFp25quxV3-0
 * @param children 버튼은 XL사이즈로 써야 합니다.
 */

type CTAButtonProps = {
  topSide?: ReactNode
  className?: string
  divider?: boolean
  background?: string
  animation?: boolean
}

const CTAButton = ({
  className,
  children,
  divider = true,
  topSide,
  background = 'bg-white',
  animation = false
}: PropsWithChildren<CTAButtonProps>) => {
  return (
    <>
      <motion.div
        animate="visible"
        initial={animation ? 'hidden' : 'visible'}
        transition={{
          damping: 40,
          stiffness: 600,
          type: 'spring'
        }}
        variants={{
          hidden: { y: 54 },
          visible: { y: 0 }
        }}
        className="fixed bottom-0 z-cta flex w-full max-w-body flex-col">
        {topSide && topSide}
        <div
          className={classNames(
            ` flex h-[calc(var(--safe-area-inset-bottom)+8rem)] w-full max-w-body items-start gap-10 py-12 px-16 ${
              divider ? 'border-t-1 border-t-gray-100' : ''
            }`,
            background,
            className
          )}>
          {children}
        </div>
      </motion.div>
      <div className="opacity-0">{topSide && topSide}</div>
      <div className={classNames('min-h-[calc(var(--safe-area-inset-bottom)+8rem)] bg-transparent', className)} />
    </>
  )
}

export default CTAButton
