import { useEffect, useId } from 'react'
import Portal from '@/components/common/Portal'
import Spinner from '@/components/layouts/Spinner'
import NativeBridgeContainer from '@/containers/app/NativeBridgeContainer'

type MutateLoadingSpinnerProps = {
  isLoading: boolean
}

const MutateLoadingSpinner = ({ isLoading }: MutateLoadingSpinnerProps) => {
  const key = useId()
  const { addBackKeyHandler, removeBackHandler } = NativeBridgeContainer.useContainer()

  useEffect(() => {
    if (isLoading) {
      addBackKeyHandler(key, () => true)
    } else {
      removeBackHandler(key)
    }

    return () => {
      removeBackHandler(key)
    }
  }, [isLoading])

  if (!isLoading) return null

  return (
    <Portal id="loading">
      <div className="fixed bottom-0 flex h-screen w-screen max-w-body items-center justify-center">
        <Spinner />
      </div>
    </Portal>
  )
}

export default MutateLoadingSpinner
