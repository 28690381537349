import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useTranslation } from 'next-i18next'
import BottomSheetLegacy from '@/components/common/design-system/BottomSheetLegacy'
import Button, { ButtonSizeEnum, ButtonThemeEnum } from '@/components/common/design-system/Button'
import CTAButton from '@/components/common/design-system/CTAButton'
import { I18nNamespaceEnum } from '@/constants/i18n'
import { BottomSheetControlsLegacyType } from '@/hooks/common/useBottomSheetLegacy'
import useLocale from '@/hooks/common/useLocale'
import { isHidingFinancialDealTerms } from '@/pages/_app'
import { HTMLMarkupType } from '@/types/html.type'

export enum PolicyTypeEnum {
  ServiceTerms = 'serviceTerms',
  FinancialDealTerms = 'financialDealTerms',
  PrivacyPolicy = 'privacyPolicy'
}

type PolicyBottomSheetProps = {
  bottomSheetControls: BottomSheetControlsLegacyType<PolicyBottomSheetDataType>
}

export type PolicyBottomSheetDataType = {
  selectedPolicyType: PolicyTypeEnum
}

const PolicyBottomSheetContent = ({ bottomSheetControls }: PolicyBottomSheetProps) => {
  const { t } = useTranslation([I18nNamespaceEnum.Common])
  const { locale } = useLocale()
  const { bottomSheetData, setBottomSheetData, handleClose } = bottomSheetControls
  const { selectedPolicyType } = bottomSheetData
  const scrollBoxRef = useRef<HTMLDivElement>(null)
  const [policyMarkup, setPolicyMarkup] = useState<HTMLMarkupType>()

  const getPolicyData = async () => {
    const data = await axios.get(`/policy/${locale}/${selectedPolicyType}.html`)
    const markupText = data.data
    setPolicyMarkup({ __html: markupText })
  }

  useEffect(() => {
    if (selectedPolicyType === PolicyTypeEnum.PrivacyPolicy && scrollBoxRef.current) {
      scrollBoxRef.current.scroll({ behavior: 'smooth', left: 500 })
    }
    getPolicyData()
  }, [selectedPolicyType])

  return (
    <>
      <BottomSheetLegacy.Header bottomSheetControls={bottomSheetControls}>
        {t('common:terms.terms_of_use')}
      </BottomSheetLegacy.Header>
      <div
        ref={scrollBoxRef}
        className="flex min-h-64 w-full flex-nowrap gap-8 overflow-y-hidden overflow-x-scroll scroll-smooth px-16 pt-12 pb-16 scrollbar-hide">
        <Button
          theme={ButtonThemeEnum.Chip}
          className="body3"
          active={selectedPolicyType === PolicyTypeEnum.ServiceTerms}
          onClick={() => {
            setBottomSheetData({ selectedPolicyType: PolicyTypeEnum.ServiceTerms })
            scrollBoxRef.current?.scroll({ behavior: 'smooth', left: 0 })
          }}>
          {t('common:terms.service_terms_of_use')}
        </Button>
        {!isHidingFinancialDealTerms() && (
          <Button
            theme={ButtonThemeEnum.Chip}
            className="body3"
            active={selectedPolicyType === PolicyTypeEnum.FinancialDealTerms}
            onClick={(e) => {
              setBottomSheetData({ selectedPolicyType: PolicyTypeEnum.FinancialDealTerms })
              e.currentTarget.scrollIntoView({ behavior: 'smooth', inline: 'center' })
            }}>
            {t('common:terms.eft_terms_and_conditions')}
          </Button>
        )}
        <Button
          theme={ButtonThemeEnum.Chip}
          className="body3"
          active={selectedPolicyType === PolicyTypeEnum.PrivacyPolicy}
          onClick={() => {
            setBottomSheetData({ selectedPolicyType: PolicyTypeEnum.PrivacyPolicy })
            scrollBoxRef.current?.scroll({ behavior: 'smooth', left: 500 })
          }}>
          {t('common:terms.privacy_policy')}
        </Button>
      </div>
      <div className="w-full overflow-y-scroll px-16">
        <div dangerouslySetInnerHTML={policyMarkup} />
      </div>
      <CTAButton divider={false}>
        <Button theme={ButtonThemeEnum.Primary} size={ButtonSizeEnum.ExtraLarge} full onClick={() => handleClose()}>
          <span className="body1 font-bold">{t('common:confirm')}</span>
        </Button>
      </CTAButton>
    </>
  )
}

const PolicyBottomSheet = ({ bottomSheetControls }: PolicyBottomSheetProps) => {
  return (
    <BottomSheetLegacy bottomSheetControls={bottomSheetControls} height="h-[80vh]">
      <PolicyBottomSheetContent bottomSheetControls={bottomSheetControls} />
    </BottomSheetLegacy>
  )
}

export default PolicyBottomSheet
